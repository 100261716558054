import { Layout } from '@components/layout';
import { RegistrationPageTitle, RegistrationForm } from '@components/standard';
import type { IPage, ILocation } from '@standardTypes';
import React from 'react';

interface RegistrationPageProps {
  pageContext: {
    page: IPage;
  };
  location: ILocation;
}

const RegistrationPage: React.FC<RegistrationPageProps> = ({ pageContext }) => {
  const { page } = pageContext;

  return (
    <Layout {...page}>
      <RegistrationPageTitle />
      <RegistrationForm />
    </Layout>
  );
};

export default RegistrationPage;
